@import url(https://fonts.googleapis.com/css2?family=Comic+Neue:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@font-face {
  font-family: 'Gilroy';
  src: url(/static/media/Gilroy-Heavy.ffe93981.ttf) format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Gilroy';
  src: url(/static/media/Gilroy-Bold.bf181b07.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Gilroy';
  src: url(/static/media/Gilroy-Medium.23dfbc19.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Gilroy';
  src: url(/static/media/Gilroy-Regular.319e0b95.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Gilroy';
  src: url(/static/media/Gilroy-Light.896263ef.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Frutiger';
  src: url(/static/media/Frutiger.34a475e6.ttf) format('truetype');
  font-weight: Medium;
}

@font-face {
  font-family: 'Frutiger';
  src: url(/static/media/Frutiger_bold.24463160.ttf) format('truetype');
  font-weight: bold;
}

:root {
  --font-size-adjustment: 1;
}

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: calc(1 * 1rem);
  font-size: calc(var(--font-size-adjustment) * 1rem); /* 2 */
}

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: calc(1 * 1rem);
  font-size: calc(var(--font-size-adjustment) * 1rem); /* 2 */
}

small {
  font-size: calc(1 * 80%);
  font-size: calc(var(--font-size-adjustment) * 80%);
}

h1 {
  font-weight: 900;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Gilroy', 'museo-sans', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4f4f4f;
  font-size: calc(1 * 1rem);
  font-size: calc(var(--font-size-adjustment) * 1rem);
  box-sizing: border-box;
}

h1 {
  font-size: calc(1 * 4rem);
  font-size: calc(var(--font-size-adjustment) * 4rem)
}

h2 {
  font-size: calc(1 * 3rem);
  font-size: calc(var(--font-size-adjustment) * 3rem);
}

h3 {
  font-size: calc(1 * 2rem);
  font-size: calc(var(--font-size-adjustment) * 2rem);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%; /* 2 */
  --antd-wave-shadow-color: none !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: 'Frutiger', 'museo-sans', sans-serif;
  font-size: calc(1 * 1rem);
  font-size: calc(var(--font-size-adjustment) * 1rem);
}

/* make sure consistent input heights */
input {
  height: 46px;
  word-wrap: break-word !important;
    overflow-wrap: break-word !important;
    -ms-word-break: break-all !important;
    word-break: break-word !important;
}

/* remove the padding in collaps panels */
.ant-collapse-content-box {
  padding: 0 !important
}

ant-collapse-header
/* style for the Select component in AddTrainer view */
.add-trainer__select .ant-select-selection {
  white-space: nowrap;
  height: 50px;
  overflow: auto
}
.ant-select-dropdown-menu-item {
  white-space: normal !important;

}

.add-trainer .ant-select-selection .ant-select-selection__rendered{
  line-height: 48px
}

.sign-up .ant-form-item {
  margin: 0 auto;
  margin-bottom: 24px;
}

/* sweet alert customisation */
.custom_swal_container {
  background: rgba(0, 0, 0, 0.7) !important;
}

.custom_swal_title {
  text-align: left !important;
  color: #26252F !important;
  font-size: calc(1 * 1rem) !important;
  font-size: calc(var(--font-size-adjustment) * 1rem) !important;
  padding: 0 !important;
  margin-bottom: 20px !important;
}

.custom_swal_actions {
  -ms-flex-pack: start !important;
      justify-content: flex-start !important;
  margin-top: 10px !important;
  width: 100% !important;
}

.custom_swal_button {
  margin: 0 !important;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
  width: 45% !important;
  font-weight: 700 !important;
}

.custom_swal_button.swal2-cancel {
  color: black;
}

.custom_swal_popup {
  padding: 2rem !important;
  border-radius: 12px !important;
}
 /* to override body margin from antd */
 .sb-show-main.sb-main-padded {
  margin: 0;
  padding: 0;
}

.ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 0 !important;
}

::selection {
  background: #EEEBF0 !important;
}


 /* to override body margin from antd */
 .sb-show-main.sb-main-padded {
  margin: 0;
  padding: 0;
}

/* to override elipsis on dropdown options from antd */
.ant-select-item-option-content {
  white-space: normal;
  height: auto;
}

.ant-select-dropdown-menu-item {
  font-size: calc(1 * 16px) !important;
  font-size: calc(var(--font-size-adjustment) * 16px) !important;
  padding: 8px 8px 8px 16px !important;
  color: #5A5961 !important;
}

.ant-select-selection__placeholder {
  color: #4A4A49 !important;
}


.ant-select-dropdown-menu-item-selected {
  font-size: calc(1 * 16px) !important;
  font-size: calc(var(--font-size-adjustment) * 16px) !important;
  background-color: #EEEBF0 !important;
  color: #006080 !important;
  padding: 8px 8px 8px 16px !important;
}

.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-right: 32px !important;
}

.ant-select-dropdown-menu-item-active {
  background-color: white;
  color: #5A5961 !important;
}

/* style popover */
.ant-popover {
  border-radius: 12px !important;
}

.ant-popover-inner {
  background: #FDC800 !important;
  border-color: #FDC800 !important;
  border-radius: 12px !important;
}

.ant-popover-arrow {
  background: #FDC800 !important;
  border-color: #FDC800 !important;
}

.ant-popover-inner-content {
  color: white !important;
}


textarea:-ms-input-placeholder {
  font-family: 'Museo Sans';
  font-style: normal;
  font-weight: 400;
  font-size: calc(1 * 16px);
  font-size: calc(var(--font-size-adjustment) * 16px);
  line-height: 160%;
  color: #4A4A49;
}


textarea::placeholder {
  font-family: 'Museo Sans';
  font-style: normal;
  font-weight: 400;
  font-size: calc(1 * 16px);
  font-size: calc(var(--font-size-adjustment) * 16px);
  line-height: 160%;
  color: #4A4A49;
}

.ant-pagination-simple .ant-pagination-simple-pager input{
  min-height: unset;
}

.skip-link {
  position: absolute;
  top: -60px;
  left: 0;
  background: #000;
  color: #fff;
  padding: 8px;
  z-index: 99999;
}

.skip-link:focus {
  top: 0;
}

.ant-modal-body {
  display: -ms-flexbox;
  display: flex;
  padding: 20px !important;
  line-height: 1.5;
  word-wrap: break-word;
}

.ant-modal-body .ant-modal-confirm-title {
  font-size: 20px !important;
  font-weight: 600 !important;
  margin-bottom: 10px;
}

.ant-modal-centered .ant-modal {
  width: unset !important;
  max-width: 520px;
}

.ant-modal-confirm-body-wrapper {
  min-width: 400px;
}

@media (max-width: 768px) {
  .ant-modal-confirm-body-wrapper {
    min-width: 200px;
  }
}

.ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content,
.ant-modal-confirm-body .ant-modal-confirm-content p,
.ant-modal-confirm-body .ant-modal-confirm-content li {
  margin: 0 !important;
  font-size: 18px;
}

.ant-modal-confirm-body .ant-modal-confirm-content ul {
  padding-left: 8px;
}

.ant-modal-root .ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.75);
}

.ant-modal-confirm-btns {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.ant-modal-confirm-btns .ant-btn,
.ant-modal-confirm-btns .ant-btn:focus {
  font-size: calc(1 * 1rem);
  font-size: calc(var(--font-size-adjustment) * 1rem);
  line-height: 160%;
  transition: all linear 0.3s;
  min-height: 50px;
  height: 2.5rem;
  width: 100%;
  max-width: 212px;
  border-radius: 8px;
  background-color: #FDC800;
  border: 2px solid #FDC800;
  font-weight: 600;
  margin: auto;
}

.ant-modal-confirm-btns .ant-btn:hover {
  background-color: #231F20;
  border: 2px solid #231F20;
  color: #FDC800;
}

.ant-modal-confirm-btns .ant-btn-danger,
.ant-modal-confirm-btns .ant-btn-primary,
.ant-modal-confirm-btns .ant-btn-danger:focus {
  color: #FDC800;
  background-color: #231F20;
  border: 2px solid #231F20;
  margin: auto !important;
}

.ant-modal-confirm-btns .ant-btn-danger:hover,
.ant-modal-confirm-btns .ant-btn-primary:hover,
.ant-modal-confirm-btns .ant-btn-primary:focus {
  background-color: #FDC800;
  border: 2px solid #FDC800;
  color: #231F20;
}

.ant-modal-confirm-btns .ant-btn-primary {
  margin: 0 !important;
}

.ant-spin-dot-item {
  background-color: #FDC800 !important
}

